<template>
  <div id="app-wrap" :class="{'tabbar': isTabBarShow }">
    <router-view id="app" />
    <!-- <van-tabbar route v-if="isTabBarShow">
      <van-tabbar-item replace to="/task" icon="bookmark">任务列表</van-tabbar-item>
      <van-tabbar-item replace to="/add" icon="plus">添加任务</van-tabbar-item>
      <van-tabbar-item replace to="/user" icon="manager">个人中心</van-tabbar-item>
    </van-tabbar> -->
    <pay-dialog v-if="payDialogShow" />
    <login-dialog v-if="loginDialogShow" />
  </div>
</template>

<script>
import PayDialog from '@/components/Pay'
import LoginDialog from '@/components/Login'
import { getUpdateInfo } from '@/api/config'
export default {
  data () {
    return {
      isTabBarShow: true
    }
  },
  components: { 
    PayDialog,
    LoginDialog
  },
  watch: {
    $route(to) {
      if (to.name == 'choose' || to.name == 'nouser' || to.name == "home") {
        this.isTabBarShow = false
      } else {
        this.isTabBarShow = true
      }
    }
  },
  async created () {
    const data = await getUpdateInfo()
    if (data) {
      this.$store.commit('setUpdateInfo', data)
    }

    await Promise.all([
      this.updateUserinfo(),
      this.updateQuantity()
    ])

    setTimeout(() => {
      this.checkAutoUpload()
    }, 100)
  }
}
</script>

<style lang="scss">
@import '@/scss/_base.scss';
#app-wrap {
  &.tabbar {
    padding-bottom: 50px;
  }
}
#app-wrap, #app {
  height: 100%;
  background-color: #f8f8f8;
}

#app {
  overflow-y: auto;
}
</style>
