import { request } from './_base'

export const getPayPackages = async () => {
  return await request({
    url: `/v1/pay/packages`,
    headers: {
      Authorization: localStorage.getItem('token') || ''
    }
  })
}

export const getPayChannels = async () => {
  return await request({
    url: `/v1/pay/channels`,
    headers: {
      Authorization: localStorage.getItem('token') || ''
    }
  })
}

export const createOrder = async ({ pid, paytype }) => {
  return await request({
    method: 'POST',
    url: `/v1/pay/order/create`,
    data: {
      pid, 
      paytype
    },
    headers: {
      Authorization: localStorage.getItem('token') || ''
    }
  })
}

export const queryOrder = async ({ tradeno }) => {
  return await request({
    method: 'POST',
    url: `/v1/pay/order/query`,
    data: {
      tradeno,
    },
    headers: {
      Authorization: localStorage.getItem('token') || ''
    }
  })
}

