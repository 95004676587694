export const EMPTY_STRING = ''

export const OFFLINE_TASK_STATUS = {
  WAITING_TORRENT: -1,
  CREATED: 1,
  WAITING: 2,
  DOWNLOADING: 3,
  FINISHED: 4,
  FAILED: 5,
  CANCELED: 6,
}

export const DOWNLOAD_STATUS = {
  ERROR: 'error',
  ACTIVE: 'active',
  PAUSED: 'paused',
  COMPLETE: 'complete',
}

