<template>
  <van-dialog
    class="dialog" 
    v-model="show" 
    :show-confirm-button="false"
  >
    <div class="content">
      <h2>请先登录一秒云盘</h2>
      <img :src="qrcode" class="qrcode" alt="">
      <p v-if="!expired" class="tip">请使用微信扫码登录</p>
      <p v-if="expired" class="tip"> 
        <span class="danger">二维码已过期</span>, 
        <span class="theme cursor" @click="getQrcode">点击重新生成</span>
      </p>
      <p class="user-tip">注册成功即表示您已知晓并同意<br>
        一秒云盘<a href="https://shimo.im/docs/YXfoljmdwgU4m4lB/" class="theme" target="_blank">《用户协议》</a>
        <a href="https://shimo.im/docs/f2Xp4u7b5HAAtYFI/" class="theme" target="_blank">《隐私保护声明》</a>
      </p>
      <van-icon class="close" name="cross" @click="close" />
    </div>
  </van-dialog>
</template>

<script>
import { createQrcode, queryQrcodeStatus } from '@/api/user'

export default { 
  data () {
    return {
      show: true,
      qrcode: '',
      qrcodeid: '',
      timer: null,
      expired: false
    }
  },
  created () {
    this.getQrcode()  
  },
  methods: {
    close () {
      clearInterval(this.timer)
      this.hideLoginDialog()
      this.$emit('close')
    },
    async getQrcode () {
      const res = await createQrcode()
      if (res.code == 0) {
        this.qrcode = res.data.image
        this.qrcodeid = res.data.qrcodeid
        this.checkQrcodeStatus()
      } else {
        return this.$message.error('获取微信二维码失败，请稍后重试')
      }
    },
    checkQrcodeStatus () {
      const total = 50
      let count = 0
      this.expired = false

      this.timer = setInterval(async () => {
        const res = await queryQrcodeStatus({ qrcodeid: this.qrcodeid })
        if (res.code == 0) {
          const token = res.data.token
          global.token = token
          localStorage.setItem('token', token)
          this.$store.commit('updateToken', token)

          await Promise.all([
            this.updateQuantity(),
            this.updateUserinfo(),
          ])
          this.$toast.success('登录成功')
          this.close()
          this.checkAutoUpload()
        } else {
          count  = count + 1
          if (count > total) {
            clearInterval(this.timer)
            this.expired = true
          }
        }
      }, 1500)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';
.content {
  padding: 20px 30px 20px;
  text-align: center;
  h2 {
    font-size: 17px;
  }
  .qrcode {
    width: 180px;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
    margin-top: 10px;
  }
  .tip {
    font-size: 14px;
    color: #666;
  }
  .user-tip {
    margin-top: 5px;
    font-size: 12px;
    color: #999;
  }
  .close {
    position: absolute;
    height: 36px;
    width: 36px;
    bottom: -46px;
    left: 50%;
    margin-left: -18px;
    color: #fff;
    font-size: 24px;
    border-radius: 50%;
    line-height: 36px;
    text-align: center;
    background-color: rgba(#000, 0.7);
    &:hover {
      cursor: pointer;
      background-color: #000;
    }
  }
}
.van-dialog {
  overflow: visible;
}
</style>