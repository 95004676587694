import mime from 'mime-types'
import * as FILE_TYPES from '@/constants/filetype'

export const getBase64 = (file) => {
  return new Promise(function (resolve, reject) {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      // 去除前面的前缀
      const arr = e.target.result.split('base64,')
      resolve(arr[1])
    }

    reader.onerror =  (error) => {
      reject(error)
    }
  })
}

export const getFileType = (filename) => {
  const mimeType = mime.lookup(filename)
  if (!mimeType) {
    return FILE_TYPES.FILE_TYPE_UNKNOWN
  }
  
  if (
    mimeType.match('ms-powerpoint') ||
    mimeType.match('officedocument.presentationml.presentation')
  ) {
    return FILE_TYPES.FILE_TYPE_PPT
  }

  if (
    mimeType.match('ms-excel') ||
    mimeType.match('officedocument.spreadsheetml.sheet')
  ) {
    return FILE_TYPES.FILE_TYPE_XLS
  }

  if (
    mimeType.match('msword') ||
    mimeType.match('officedocument.wordprocessingml.document')
  ) {
    return FILE_TYPES.FILE_TYPE_DOC
  }

  if (mimeType.match('compressed') || mimeType.match('zip') || mimeType.match('gzip')) {
    return FILE_TYPES.FILE_TYPE_ZIP
  }

  if (mimeType.match('application/pdf')) {
    return FILE_TYPES.FILE_TYPE_PDF
  }

  if (mimeType.match('application/x-bittorrent')) {
    return FILE_TYPES.FILE_TYPE_BT
  }

  if (mimeType.match('audio/')) {
    return FILE_TYPES.FILE_TYPE_AUDIO
  }

  if (mimeType.match('video/')) {
    return FILE_TYPES.FILE_TYPE_VIDEO
  }

  if (mimeType.match('image/')) {
    return FILE_TYPES.FILE_TYPE_IMG
  }

  return FILE_TYPES.FILE_TYPE_UNKNOWN
}

export const parseLocationSearchString = (str) => {
  str = str || location.search
  let qs = str.length > 0 ? str.substring(1) : ''
  let args = {}
  let items = qs.length > 0 ? qs.split('&') : []
  let item = null,name = null,value = null
  for(let i = 0;i < items.length;i++) {
      item = items[i].split('=');
      name = decodeURIComponent(item[0]);
      value = decodeURIComponent(item[1]);
      if(name.length) {
          args[name] = value;
      }
  }
  return args
}

export const generateLocationSearchString = (obj) => {
  let searchString = ''
  for (let key in obj) {
    if (!searchString) {
      searchString = `?${key}=${obj[key]}`
    } else {
      searchString = `${searchString}&${key}=${obj[key]}`
    }
  }
  return searchString
}

const accsessUrlReg = /https?:\/\/(pan|yun)\.baidu\.com\/s\/1([a-zA-Z0-9_-]{5,22})/im
const initUrlReg = /https?:\/\/(pan|yun)\.baidu\.com\/share\/init\?surl=([a-zA-Z0-9_-]{5,22})/im
const codeReg = /提取码[:：]\s*([a-zA-Z0-9_-]{4,6})/im

export const parseLinkAndCode = (content) => {
  // 提取链接
  let url = ''
  if (accsessUrlReg.test(content)) {
    let t = accsessUrlReg.exec(content)
    url = t ? t[2] : null 
  }

  if (initUrlReg.test(content)) {
    let t = initUrlReg.exec(content)
    url = t ? t[2] : null 
  }
  
  let code = ''
  // 提取码
  if (codeReg.test(content)) {
    let t = codeReg.exec(content)
    code = t ? t[1] : null 
  }

  if (url) {
    return {
      url: `https://pan.baidu.com/s/1${url}`,
      code
    }
  } else {
    return {
      url: null,
      code: null
    }
  }
}

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(window.navigator.userAgent)
}

export const isWeixin = () => { 
  return /MicroMessenger/i.test(window.navigator.userAgent)
}

export const isIOS = () => {
  return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
}

export const isAndroid = () => {
  return /(Android)/i.test(navigator.userAgent)
}