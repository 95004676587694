import { request } from '@/api/_base'

export const wxLogin = async (code) => {
  return await request({
    url: `/v1/h5/wx/login`,
    params: {
      code
    }
  })
}

export const createQrcode = async () => {
  return await request({
    url: `/v1/mp/qrcode/create`
  })
}

export const queryQrcodeStatus = async ({ qrcodeid }) => {
  return await request({
    url: `/v1/mp/qrcode/status`,
    params: {
      qrcodeid
    }
  })
}

export const getTaskList = async () => {
  return await request({
    url: '/v1/h5/task/list',
    headers: {
      authorization: localStorage.getItem('token') || ''
    }
  })
}

export const addTask = async ({ pwd, url, index }) => {
  return await request({
    method: 'POST',
    url: '/v1/h5/task/add',
    headers: {
      authorization: localStorage.getItem('token') || ''
    },
    data: {
      url, pwd, index
    }
  })
}

export const retryTask = async ({ taskid }) => {
  return await request({
    method: 'POST',
    url: '/v1/h5/task/retry',
    headers: {
      authorization: localStorage.getItem('token') || ''
    },
    data: {
      taskid
    }
  })
}

export const parseLink = async ({ url, pwd }) => {
  return await request({
    method: 'POST',
    url: '/v1/h5/link/parse',
    headers: {
      authorization: localStorage.getItem('token') || ''
    },
    data: {
      url, pwd
    }
  })
}

export const getUserinfo = async () => {
  return await request({
    url: '/v1/user/info',
    headers: {
      authorization: localStorage.getItem('token') || ''
    }
  })
}


export const getAvailableQuantity = async () => {
  return await request({
    url: `/v1/user/traffic/available`,
    headers: {
      Authorization: localStorage.getItem('token') || ''
    }
  })
}
