import Vue from 'vue'
import VueRouter from 'vue-router'
// import { wxLogin } from '@/api/user'
// import { mpAppId } from '../constants/mp'
// import { parseLocationSearchString, isWeixin, isMobile } from '@/util'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue')
  },
  // {
  //   path: '/task',
  //   name: 'task',
  //   meta: {
  //     requiredAuth: true,
  //   },
  //   component: () => import('@/views/Task.vue')
  // },
  {
    path: '/user',
    name: 'user',
    meta: {
      requiredAuth: true,
    },
    component: () => import('@/views/User.vue')
  },
  // {
  //   path: '/add',
  //   name: 'add',
  //   meta: {
  //     requiredAuth: true,
  //   },
  //   component: () => import('@/views/Add.vue')
  // },
  // {
  //   path: '/choose',
  //   name: 'choose',
  //   meta: {
  //     requiredAuth: true,
  //   },
  //   component: () => import('@/views/Choose.vue')
  // },
]

const router = new VueRouter({
  routes
})

// const redirectMpAuth = (url) => {
//   url = url || 'https://m.feisuxia.com/#/'
//   window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${mpAppId}&redirect_uri=${encodeURIComponent(url)}&response_type=code&scope=snsapi_userinfo#wechat_redirect`
// }

// router.beforeEach(async (to, from, next) => {
//   if (!isMobile()) {
//     const hash = window.location.hash
//     if (hash.includes('?')) {
//       const arr = hash.split('?')
//       window.location.href = `https://www.feisuxia.com/#/?${arr[1]}`
//     } else {
//       window.location.href = `https://www.feisuxia.com/#/`
//     }
//   }

//   const token = localStorage.getItem('token')
//   global.token = token
//   if (!global.token) {
//     if (isWeixin()) {
//       const queryObj = parseLocationSearchString(window.location.search)
//       if (queryObj.code) {
//         const res = await wxLogin(queryObj.code)
//         if (res.code == 2002) {
//           return redirectMpAuth(window.location.href)
//         } else if (res.code == 0) {
//           localStorage.setItem('token', res.data.token)
//           window.location.reload()
//         } else if (res.code == 2003) {
//           next('/nouser')
//         }
//       } else {
//         return redirectMpAuth(window.location.href)
//       }
//     }

//     if (!isWeixin() && to.meta.requiredAuth) {
//       next('/')
//     }
//   }

//   next()
// })

export default router
