import dayjs from 'dayjs'
import { mapState, mapGetters } from 'vuex'
import { mpAppId } from '@/constants/mp'
import { isWeixin } from '@/util'
import { getAvailableQuantity, getUserinfo } from '@/api/user'
import { OFFLINE_TASK_STATUS, DOWNLOAD_STATUS } from '@/constants/common'


Array.prototype.remove = function(val) {
  var index = this.indexOf(val)
  if (index > -1) {
    this.splice(index, 1)
  }
}

const install = (Vue) => {
  Vue.mixin({
    data () {
      return {
        DOWNLOAD_STATUS,
        OFFLINE_TASK_STATUS,
      }
    },
    computed: {
      ...mapState([
        'token',
        'isLogin',
        'userinfo',
        'quantity',
        'updateInfo',
        'payDialogShow',
        'loginDialogShow',
      ]),
      ...mapGetters([
        'allQuantity',
        'quantityPackages'
      ]),
    },
    filters: {
      bytesToSize (bytes) {
        if (bytes < 0) { return '0KB' }
        const b = parseInt(bytes, 10)
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
        if (isNaN(b)) { return '0KB' }
        if (b === 0) { return '0KB' }
        const i = parseInt(Math.floor(Math.log(b) / Math.log(1024)), 10)
        if (i === 0) { return `${b}${sizes[i]}` }
        if (i > 1) {
          return `${(b / (1024 ** i)).toFixed(1)}${sizes[i]}`
        } else {
          return `${(b / (1024 ** i)).toFixed(0)}${sizes[i]}`
        }
      },
      formateDate (value) {
        return dayjs(value).format('YYYY/MM/DD HH:mm')
      }
    },
    methods: {
      showPayDialog () {
        this.$store.commit('updatePayDialog', true)
      },
      hidePayDialog () {
        this.$store.commit('updatePayDialog', false)
      },
      showLoginDialog () {
        this.$store.commit('updateLoginDialog', true)
      },
      hideLoginDialog () {
        this.$store.commit('updateLoginDialog', false)
      },
      async updateUserinfo () {
        const res = await getUserinfo()
        if (res.code == 0) {
          this.$store.commit('updateLogin', true)
          this.$store.commit('updateUserinfo', res.data)
        } else if (res.code == 1001) {
          localStorage.setItem('token', '')
          this.$store.commit('updateToken', '')
          this.$store.commit('updateLogin', false)  
        }
      },
      async updateQuantity () {
        const res = await getAvailableQuantity()
        if (res.code == 0) {
          this.$store.commit('updateQuantity', res.data)
        }
      },
      redirectMpAuth (url) {
        url = url || 'https://m.feisuxia.com/#/'
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${mpAppId}&redirect_uri=${encodeURIComponent(url)}&response_type=code&scope=snsapi_userinfo#wechat_redirect`
      },
      goLogin () {
        if (isWeixin()) {
          return this.redirectMpAuth(window.location.href)
        } else {
          if (this.$route.meta.requiredAuth) {
            this.$router.push('/')
          }
          this.showLoginDialog()
        }  
      },
      checkAutoUpload () {
        const query = this.$route.query
        if (query.url) {
          if (this.isLogin) {
            return this.$router.push({
              path: '/choose',
              query: query
            })
          } 

          if (!this.isLogin) {
            this.goLogin()
          }
        }
      }
    }
  })
}

export default { install }
