import axios from 'axios'
// const APIHOST = 'http://localhost:6002'
const APIHOST = 'https://user.krzb.net'

export const request = async ({ method ='GET', data, url, params, headers = {}, isPrefix = true }) => {
  try {
    const res = await axios({
      method,
      url: isPrefix ? `${APIHOST}${url}` : url,
      data,
      params: {
        plt: 'h5',
        appv: '1.0.0',
        ...params
      },
      timeout: 20 * 1000,
      headers: {
        ...headers
      },
    })
    return res.data
  } catch (err) {
    console.log(`request error: ${err}`)
    if (err.response) {
      return err.response.data
    } else {
      return null
    }
  }
}